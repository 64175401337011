body,
html {
  height: 100%
}

h1,
h2,
strong {
    font-weight: 400
}

body,
html {
    height: 100%
}

body,
h1,
h2,
p {
    margin: 0
}

footer,
h2 {
    position: absolute;
    opacity: 0
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block
}

.touch body {
    -webkit-text-size-adjust: none
}

:focus {
    -moz-outline-style: none;
    outline: 0
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 100%;
    line-height: 28px;
    letter-spacing: -.01em
}


a {
    background: none;
    border: none;
    outline: 0;
    text-decoration: none;
    border-bottom: 1px solid #000;
    color: #000;
    -webkit-transition: color 1.2s ease-in-out, border-color 1.2s ease-in-out;
    -moz-transition: color 1.2s ease-in-out, border-color 1.2s ease-in-out;
    -ms-transition: color 1.2s ease-in-out, border-color 1.2s ease-in-out;
    -o-transition: color 1.2s ease-in-out, border-color 1.2s ease-in-out;
    transition: color 1.2s ease-in-out, border-color 1.2s ease-in-out;
    -webkit-tap-highlight-color: transparent
}

a:hover {
    color: #38c3f0;
    border-color: #38c3f0;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease
}

a img {
    border: 0
}

img {
    max-width: 100%
}

h1 {
    font-size: 22px;
    line-height: 35px;
    opacity: 0;
    -webkit-animation: fadeIn 2s ease-out 1 .1s;
    -ms-animation: fadeIn 2s ease-out 1 .1s;
    animation: fadeIn 2s ease-out 1 .1s;
    animation-fill-mode: forwards
}

h1 a {
    border-color: #ccc
}

h1 small {
    font-size: 70%
}

h2 {
    font-size: 30px;
    line-height: 50px;
    max-width: 1000px;
    top: 60%;
    left: 120px;
    -webkit-animation: fadeIn 2s ease-out 1 1.3s;
    -ms-animation: fadeIn 2s ease-out 1 1.3s;
    animation: fadeIn 2s ease-out 1 1.3s;
    animation-fill-mode: forwards
}

.wrapper {
    padding: 80px 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

footer {
    margin-top: 50px;
    /*left: 120px;*/
    -webkit-animation: fadeIn 1s ease-out 1 1.6s;
    -ms-animation: fadeIn 1s ease-out 1 1.6s;
    animation: fadeIn 1s ease-out 1 1.6s;
    animation-fill-mode: forwards
}

::-moz-selection {
    background: rgba(0, 0, 0, .1)!important
}

::selection {
    background: rgba(0, 0, 0, .1)
}

li::selection,
ul::selection {
    background: rgba(0, 0, 0, 0)
}

::-webkit-input-placeholder {
    color: #999
}

:-moz-placeholder {
    color: #999
}

::-moz-placeholder {
    color: #999
}

:-ms-input-placeholder {
    color: #999
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
